import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

export default function MobileCamera() {
  const camRef = useRef(null);
  const [websocket, setWebsocket] = useState(null);

  // Capture image function
  const captureImage = useCallback(() => {
    const imageSrc = camRef.current.getScreenshot();
    console.log("Captured Image:", imageSrc);

    // Send image to the WebSocket server
    if (websocket) {
      websocket.send(JSON.stringify({ type: "image", data: imageSrc }));
    }
  }, [websocket]);

  // WebSocket connection setup
  useEffect(() => {
    const ws = new WebSocket("wss://demo.annk.info/ws/");
    setWebsocket(ws);

    ws.onopen = () => {
      console.log("WebSocket connection established on mobile.");
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === "take_picture") {
        console.log("Received command to take a picture");
        captureImage(); // Trigger image capture when the command is received
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    return () => ws.close();
  }, [captureImage]);

  return (
    <>
      <Webcam ref={camRef} screenshotFormat="image/jpeg" />
      <p>Mobile Camera Ready</p>
    </>
  );
}
