import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Webcamera from "./components/Camera";
import QRReader from "./components/QR";
import WebSocketComponent from "./components/Websocket";
import WebAppControl from "./components/Websocket/WebAppControl";
import MobileCamera from "./components/Websocket/MobileCamera";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/camera2" element={<QRReader />} />
          <Route path="/video2/:qrValue" element={<Webcamera />} /> */}
          {/* <Route path="/" element={<WebAppControl />} /> */}
          <Route path="/" element={<MobileCamera />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
